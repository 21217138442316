<template>
  <div>
    <div>
      <h1>{{ $t('nav.account.request-assistance')}}</h1>
      <p>
        Have a question? Check our knowledge base for instant answers, or email us for a personal touch.
      </p>
      <p>
        <v-btn color="primary" :href="tenant.profileFaqUrl">Explore Knowledge base</v-btn>
      </p>
      <p>
        <v-btn outlined color="primary" :href="`mailto:${tenant.contactEmail}`">Send an email</v-btn>
      </p>
    </div>
  </div>
</template>


<script>
import siteData from '@/data/site.json'
import { mapGetters } from "vuex";
import { DateTime } from 'luxon'
import { EventBus } from '@/plugins/eventbus.js';
import BigButton from "@/components/BigButton";
import platformService from "@/services/platformService";
import profileService from "@/services/profileService";
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "Contact",
  components: {
    BigButton,
  },
  props: {
  },
  data: function() {
    return {
      tenant: tenant,
      siteData: siteData,
      now: DateTime.now(),
      DateTime: DateTime,
      currentSlideIndex: 0,
      interval: 0,
      valid: true,
      mode: 'contact',
      timeZoneOlson: null,
      org: '',
      phone: '',
      profileId: '',
      name: '',
      nameRules: [
        v => !!v || 'Name is required',
      ],
      orgRules: [
        v => !!v || 'Please enter the name of your organization',
      ],
      phoneRules: [
        v => !!v || 'Please enter a phone number where we can reach you',
      ],
      email: '',
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      comments: '',
      meetingDate: '',
    };
  },
  created() {
    var maxIndex = siteData.customers.filter(x => x.corporate).length;
    this.interval = setInterval(() => this.currentSlideIndex > maxIndex ? this.currentSlideIndex = 0 : this.currentSlideIndex++, 1000);
  },
  beforeDestroy () {
    clearInterval(this.interval);
  },
  async mounted() {
    //this.track();
    this.mode = this.$route.query.mode || this.mode;
    this.name = this.$route.query.name;
    this.email = this.$route.query.email;
    this.comments = this.$route.query.comments;
    this.timeZoneOlson = DateTime.now().zoneName;

    if (this.user) {
      this.loadProfile();
    }
    EventBus.$on('login-state-change', async user => {
      if (user) {
        this.loadProfile();
      }
    });

    console.log('// analytics', this.$analytics.synced);
  },
  methods: {
    
    async loadProfile() {
      var response = await profileService.get();
      this.profileId = response.data.id;
      if (!response.data.random_name) {
        this.name = response.data.name;
      }
      this.email = response.data.email;
    },
    track () {
    }
  },
  computed: {
    ...mapGetters({
      user: "user"
    })
  }
};
</script>

<style scoped>
</style>